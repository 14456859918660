<template>
  <div class="resident-box">
    <el-container>
      <el-header height>
        <headers></headers>
      </el-header>
      <el-row>
        <el-col :span="12" :xs="8" :offset="6">
          <el-main>
            <sui-form
              ref="form"
              :list="formList"
              :defaultdata="formData"
              @submit="submit"
            />
          </el-main>
        </el-col>
      </el-row>
    </el-container>
  </div>
</template>

<script>
import SuiForm from "@/components/s-ui/form";
import Headers from "@/components/Header";
import { AddStore } from "@/utils/api";

export default {
  components: { SuiForm, Headers },
  props: {
    type: {
      type: Number,
      value: 0,
    },
    name: {
      type: String,
      value: "",
    },
  },
  data() {
    return {
      // form表单的内容
      formList: [
        // 0供应商;1物流商
        {
          type: "radio",
          name: "kind",
          rules: [{ required: true }],
          range: [
            {
              label: "shop.resident.businessman",
              value: 0,
            },
            {
              label: "shop.resident.logisticsProvider",
              value: 1,
            },
          ],
          // 设置点击radio时是否隐藏分类categoryIdList 0供应商出现分类
          setHidden: ["categoryIdList", 0, 0],
        },
        // 商家信息
        {
          type: "title",
          label: "shop.resident.storeinfo",
        },
        // 店名
        {
          type: "input",
          name: "storeName",
          label: "shop.resident.storeName",
          rules: [
            {
              required: true,
              message: "shop.resident.storeNameReq",
              trigger: "blur",
            },
          ],
        },
        // 主要分类
        {
          type: "categoryselect",
          name: "categoryIdList",
          label: "shop.resident.categoryIdList",
          rules: [
            {
              required: true,
              message: "shop.resident.categoryselect",
              trigger: "blur",
            },
          ],
        },
        // 企业认证
        {
          type: "title",
          label: "shop.resident.Enterprise",
        },
        // 企业名称
        {
          type: "input",
          name: "enterpriseName",
          label: "shop.resident.enterpriseName",
          rules: [
            {
              required: true,
              message: "shop.resident.enterpriseNameReq",
              trigger: "blur",
            },
          ],
        },
        // 公司地址
        {
          type: "input",
          name: "address",
          label: "shop.resident.address",
          rules: [
            {
              required: true,
              message: "shop.resident.addressReq",
              trigger: "blur",
            },
          ],
        },
        // 公司简介
        {
          type: "textarea",
          name: "profile",
          label: "shop.resident.profile",
          rules: [
            {
              required: true,
              message: "shop.resident.profileReq",
              trigger: "blur",
            },
          ],
        },
        // 营业执照
        {
          type: "uploadimage",
          name: "businessLicenseImg",
          label: "shop.resident.businessLicenseImg",
          rules: [
            {
              required: true,
              message: "shop.resident.businessLicenseImgReq",
              trigger: "blur",
            },
          ],
        },
        // 身份证正面照
        {
          type: "uploadimage",
          name: "identityCardFront",
          label: "shop.resident.identityCardFront",
          rules: [
            {
              required: true,
              message: "shop.resident.identityCardFrontReq",
              trigger: "blur",
            },
          ],
        },
        // 身份证反面照
        {
          type: "uploadimage",
          name: "identityCardBack",
          label: "shop.resident.identityCardBack",
          rules: [
            {
              required: true,
              message: "shop.resident.identityCardBackReq",
              trigger: "blur",
            },
          ],
        },
        // 品牌标志
        {
          type: "uploadimage",
          name: "brandLogo",
          label: "shop.resident.brandLogo",
        },
        // 产品证书
        {
          type: "uploadimage",
          name: "productCert",
          label: "shop.resident.productCert",
        },
        // 商标注册证
        {
          type: "uploadimage",
          name: "trademarkCert",
          label: "shop.resident.trademarkCert",
        },
      ],
      // form的默认参数
      formData: {
        kind: 0,
        categoryIdList: [],
      },
    };
  },
  methods: {
    // 表单提交的点击事件
    submit(data) {
      console.log("submit:", data);
      // 添加店铺的接口
      const fn = AddStore;
      fn(data)
        .then((res) => {
          console.log("AddStore-res:", res);
          // 添加成功显示message
          this.$message({
            type: "success",
            message: this.$t("common.createSuccess"),
          });
          localStorage.removeItem("vendor_user_token");
          this.$router.push("/v2/login");
        })

        .catch((err) => {
          console.log("AddBanner-err:", err);
        });
    },
  },
};
</script>